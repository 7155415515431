body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  background-color: #081217;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.mapboxgl-ctrl-logo {
  display: none;
}

.mapboxgl-popup-content {
  background-color: rgba(255, 255, 255, 0.877);
  border-radius: 10px;
  width: 250px;
}

.cases p {
  font-size: 20px;
}

.total-cases {
  color: red;
}

.death-cases {
  color: rgb(194, 129, 10);
}

.recovered-cases {
  color: green;
}

.map-overlay {
  font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
  position: absolute;
  /* width: 250px; */
  top: 10px;
  left: 10px;
  padding: 10px;
  margin: 0;
  color: white;
  background-color: rgba(0, 0, 0, 0.212);
  border: 1px solid rgba(255, 255, 255, 0.63);
}

.map-overlay fieldset {
  border: none;
  padding: 0;
  margin: 0 0 10px;
}

.map-overlay fieldset legend {
  font-size: 24px;
  text-align: right;
  padding: 10px 0 10px 10px;
}

.map-overlay-content {
  text-align: right;
}

.map-overlay-content h1 {
  font-size: 30px;
  font-weight: bold;
  margin: 20px 0;
}

.map-overlay-content p {
  margin: 0;
  font-size: 16px;
}

.togle-mapbox-overlay {
  display: flex;
  font-size: 16px;
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
}
.togle-mapbox-overlay p{
  margin-left: 5px;
  margin-top: 0;
  margin-bottom: 0;
}

.hide-overlay {
  display: none;
}