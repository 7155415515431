.map-container {
  height: 100vh;
  }

.container{
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

